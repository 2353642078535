import React from "react"

import { navigate } from "gatsby"

import PageLoader from "components/page-loader"

type RedirectType = {
  url: string
}

const Redirect = ({ url }: RedirectType) => {
  if (typeof window !== "undefined") {
    navigate(url, { replace: true })
  }
  return <PageLoader />
}

export default Redirect
