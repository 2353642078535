import React from "react"

import styled from "styled-components"

import Loading from "@kiwicom/orbit-components/lib/Loading"

import Layout from "components/layout"

const PageLoaderWrapper = styled.div`
  margin: auto;
`

type PageLoaderProps = {
  title?: string
  text?: string
  dataTest?: string
}

const PageLoader = ({ title, text, dataTest }: PageLoaderProps) => (
  <Layout
    title={title ? title : "Loading"}
    hideContent={true}
    dataTest={dataTest}
  >
    <PageLoaderWrapper>
      <Loading type="pageLoader" text={text} />
    </PageLoaderWrapper>
  </Layout>
)

export default PageLoader
